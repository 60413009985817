import {h, render, Component} from 'preact';

class Feds extends Component {
  constructor() {
    super();

    this.state = {
      fedList: []
    };
  }

  componentWillMount() {
    fetch('APIBASE/V1/Fed')
      .then(response => {
        return response.json();
      })
      .then(fedList => {
        this.setState({fedList});
      });
  }


  render() {
    return (
      <div>
        {this.state.fedList.map(fed => {
          return (
            <div className="pr-sep">
              <div className="pr-sep-square">
                <div>
                  <a href={fed.url}><img
                    src={fed.logo}
                    style="height:40px"/></a>
                </div>
                <div class="pr-sep-fedname">
                  <a href={fed.url}>{fed.name}</a>
                </div>
              </div>
            </div>
          )

        })}
      </div>
    )
  }


}

export default Feds;