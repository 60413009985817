import {h, render, Component} from 'preact';
import Feds from "./Feds.jsx";
import Races from "./Races.jsx";

class FedListFront extends Component {
  constructor() {
    super();

    this.state = {
      route: "fedlist"
    };
  }

   render() {
    return (

      <div>
        <SubMenu route={this.state.route} onClick={route => {
          this.setState({route});
        }}/>
        <ShowByRoute route={this.state.route}/>
      </div>
    )
  }
}


const ShowByRoute = ({route}) => {
  if (route === "fedlist") return <Feds/>;
  if (route === "racelist") return <Races/>;

};

const SubMenu = ({route, onClick}) => {
  return (
    <div className="marketing-submenu">
      <ul>
        <li><a href="#" onClick={() => {
          onClick("fedlist")
        }} className={route === "fedlist" ? "selected" : ""}>Feds</a></li>
        <li><a href="#" onClick={() => {
          onClick("racelist")
        }} className={route === "racelist" ? "selected" : ""}>Show Upcoming Races</a></li>
      </ul>
    </div>
  );
};

export default FedListFront;