import {h, render, Component} from 'preact';
import moment from "moment";

const getDifference = date => {
  const dateString = moment.unix(date);
  const now = moment();
  return dateString.diff(now, "days");
};

const differenceStringify = date => {
  const d = getDifference(date);
  if (d < 0) return "Past Races";
  if (d < 4) return "Upcoming Races";
  return "Future Races";

};


class Races extends Component {
  constructor() {
    super();

    this.state = {
      raceList: []
    }
  }

  componentWillMount() {

    fetch('APIBASE/V1/Race')
      .then(response => {
        return response.json();
      })
      .then(raceList => {
        console.log("racelist", raceList);
        this.setState({raceList});
      });
  }


  render() {
    let lastTimeContext = "";
    return (
      <div>
        {this.state.raceList.map(fed => {
          const raceName = fed.ammend_to_name ? fed.race_name + " (" + fed.ammend_to_name + ")" : fed.race_name;
          const timeContext = differenceStringify(fed.lib_time_unix);
          let headingText = undefined;
          if (lastTimeContext !== timeContext) {
            lastTimeContext = timeContext;
            headingText = timeContext;
          }
          return (
            <div className="pr-races-sep">

              {headingText && <h2 style={{background: "#444C6A", padding: "8px"}}>{headingText}</h2>}

              <div className="pr-sep-square">


                <div className="pr-sep-fedname">
                  <a href={fed.url}>{fed.name}</a>
                </div>

                <div className="pr-sep-time">

                  {fed.lib_time_display &&
                  <span>{fed.lib_date_display} at {fed.lib_time_display} from {raceName}</span>
                  }

                  {!fed.lib_time_display &&
                  <span>{fed.lib_date_display} from {raceName}</span>
                  }

                </div>

              </div>
            </div>
          )

        })}
      </div>
    )
  }


}

export default Races;

/*


                {(fed.special_news || fed.lib_report) &&
                <div className="pr-sep-report">
                  {fed.special_news &&
                  <div className="spesh">{fed.special_news}</div>
                  }
                  <div className="libreport">{fed.lib_report}</div>
                </div>
                }
 */